// @flow

import { format, differenceInCalendarDays, getMonth, getYear } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import {
  toUpper,
  upperFirst,
  orderBy,
  isEmpty,
  filter,
  uniqBy,
  reduce,
} from 'lodash';

// Date will look like this: VEN. 18 NOVEMBRE
export const formatDateLong = date => {
  const formatedDate = format(date, 'ddd D MMMM', { locale: frLocale });
  return toUpper(formatedDate);
};

// Date will look like this: Vendredi 18 novembre
export const formatDateLongFirstLetterUpper = date => {
  const formatedDate = format(date, 'dddd D MMMM', { locale: frLocale });
  return upperFirst(formatedDate);
};

// Date will look like this: Novembre 2018
export const formatDateMonthYear = date => {
  const formatedDate = format(date, 'MMMM YYYY', { locale: frLocale });
  return upperFirst(formatedDate);
};

// Date will look like this: 2018-02
export const formatMonthYearId = date => {
  const formatedDate = format(date, 'YYYY-MM', { locale: frLocale });
  return formatedDate;
};

export const formatDateCalendar = date => {
  const formatedDate = format(date, 'YYYYMMDD', { locale: frLocale });
  return formatedDate;
};

export const numberOfDays = (endDate, startDate) => {
  return differenceInCalendarDays(endDate, startDate);
};

export const filterEventList = (eventList, month, year) => {
  if (isEmpty(eventList)) {
    return [];
  }
  let dataSource = eventList;

  // Filter by mmonth
  if (month > 0 && year > 0) {
    dataSource = filter(
      dataSource,
      obj =>
        (getMonth(obj.start_date) + 1 === month &&
          getYear(obj.start_date) === year) ||
        (getMonth(obj.end_date) + 1 === month &&
          getYear(obj.end_date) === year),
    );
  }
  dataSource = orderBy(dataSource, ['start_date'], ['asc']);
  return dataSource;
};

export const GenerateDateFilterListDataSource = eventsList => {
  let dataSource = eventsList;
  dataSource = reduce(
    dataSource,
    (acc, next) => {
      acc.push({
        key: formatMonthYearId(next.start_date),
        label: formatDateMonthYear(next.start_date),
      });
      acc.push({
        key: formatMonthYearId(next.end_date),
        label: formatDateMonthYear(next.end_date),
      });
      return acc;
    },
    [],
  );
  dataSource = uniqBy(dataSource, 'key');
  dataSource = orderBy(dataSource, ['key'], ['asc']);
  console.group('Calculate options filter');
  console.log(dataSource);
  console.groupEnd();
  return dataSource;
};
