import React from 'react';
import ReactDOM from 'react-dom';
import News from './News.jsx';
import Events from './Events.jsx';
import Diaporama from './Diaporama.jsx';
import RadioButtons from './utils/RadioButtons';
import './intramuros.css';

export function unmountWidget(id) {
  if (document.getElementById(id)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(id));
  }
}

export function initNewsWidget(cityId, aggloId, cityINSEE, aggloSIREN) {
  console.log('Init IntraMuros News widget');
  unmountWidget('intramuros_news');
  if (document.getElementById('intramuros_news')) {
    ReactDOM.render(
      <News
        cityId={cityId}
        aggloId={aggloId}
        cityINSEE={cityINSEE}
        aggloSIREN={aggloSIREN}
      />,
      document.getElementById('intramuros_news'),
    );
  } else {
    console.log('No tag with id: intramuros_news');
  }
}

export function initEventsWidget(cityId, aggloId, cityINSEE, aggloSIREN) {
  console.log('Init IntraMuros Events widget');
  unmountWidget('intramuros_events');
  if (document.getElementById('intramuros_events')) {
    ReactDOM.render(
      <Events
        cityId={cityId}
        aggloId={aggloId}
        cityINSEE={cityINSEE}
        aggloSIREN={aggloSIREN}
      />,
      document.getElementById('intramuros_events'),
    );
  } else {
    console.log('No tag with id: intramuros_events');
  }
}

export function initDiaporamaWidget(cityId, aggloId, cityINSEE, aggloSIREN) {
  console.log('Init IntraMuros Diaporama widget');
  unmountWidget('intramuros_diaporama');
  if (document.getElementById('intramuros_diaporama')) {
    ReactDOM.render(
      <Diaporama
        cityId={cityId}
        aggloId={aggloId}
        cityINSEE={cityINSEE}
        aggloSIREN={aggloSIREN}
      />,
      document.getElementById('intramuros_diaporama'),
    );
  } else {
    console.log('No tag with id: intramuros_diaporama');
  }
}

// switcher for test only
export function initSwitcher(cityId, aggloId, cityINSEE, aggloSIREN) {
  if (document.getElementById('intramuros_switcher')) {
    ReactDOM.render(
      <RadioButtons
        initEventsWidget={initEventsWidget}
        initNewsWidget={initNewsWidget}
        unmountWidget={unmountWidget}
        cityId={cityId}
        aggloId={aggloId}
        cityINSEE={cityINSEE}
        aggloSIREN={aggloSIREN}
      />,
      document.getElementById('intramuros_switcher'),
    );
  }
}

// Launch widget
console.log('Widget IntraMuros chargé.');
const {
  intramurosCityId,
  intramurosAggloId,
  intramurosCityINSEE,
  intramurosAggloSIREN,
  isDiapo,
  switcher,
} = window;

console.log('City ID = ' + intramurosCityId);
console.log('City INSEE = ' + intramurosCityINSEE);
console.log('Agglo ID = ' + intramurosAggloId);
console.log('Agglo SIREN = ' + intramurosAggloSIREN);

if (
  intramurosCityId ||
  intramurosAggloId ||
  intramurosCityINSEE ||
  intramurosAggloSIREN
) {
  if (isDiapo === 'true') {
    initDiaporamaWidget(
      intramurosCityId,
      intramurosAggloId,
      intramurosCityINSEE,
      intramurosAggloSIREN,
    );
  } else if (switcher === 'true') {
    initSwitcher(
      intramurosCityId,
      intramurosAggloId,
      intramurosCityINSEE,
      intramurosAggloSIREN,
    );
    initEventsWidget(
      intramurosCityId,
      intramurosAggloId,
      intramurosCityINSEE,
      intramurosAggloSIREN,
    );
  } else {
    initEventsWidget(
      intramurosCityId,
      intramurosAggloId,
      intramurosCityINSEE,
      intramurosAggloSIREN,
    );
    initNewsWidget(
      intramurosCityId,
      intramurosAggloId,
      intramurosCityINSEE,
      intramurosAggloSIREN,
    );
  }
}
