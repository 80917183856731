import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Radio from '@material-ui/core/Radio';

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

class RadioButtons extends React.Component {
  state = {
    selectedValue: 'Evénements',
  };

  handleChange = event => {
    this.setState({ selectedValue: event.target.value });

    if (event.target.value === 'Evénements') {
      const {
        initEventsWidget,
        unmountWidget,
        cityId,
        aggloId,
        cityINSEE,
        aggloSIREN,
      } = this.props;
      unmountWidget('intramuros_news');
      initEventsWidget(cityId, aggloId, cityINSEE, aggloSIREN);
    } else {
      const {
        initNewsWidget,
        unmountWidget,
        cityId,
        aggloId,
        cityINSEE,
        aggloSIREN,
      } = this.props;
      unmountWidget('intramuros_events');
      initNewsWidget(cityId, aggloId, cityINSEE, aggloSIREN);
    }
  };

  render() {
    const { selectedValue } = this.state;
    return (
      <div>
        {'Evénements'}
        <Radio
          checked={selectedValue === 'Evénements'}
          onChange={this.handleChange}
          value="Evénements"
          name="radio-button-demo"
          aria-label="A"
        />
        {'Actus'}
        <Radio
          checked={selectedValue === 'Actualités'}
          onChange={this.handleChange}
          value="Actualités"
          name="radio-button-demo"
          aria-label="B"
        />
      </div>
    );
  }
}

RadioButtons.propTypes = {
  initEventsWidget: PropTypes.objectOf(PropTypes.object()).isRequired,
  initNewsWidget: PropTypes.objectOf(PropTypes.object()).isRequired,
  unmountWidget: PropTypes.objectOf(PropTypes.object()).isRequired,
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default withStyles(styles)(RadioButtons);
