import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, Grid } from '@material-ui/core';
import { isEmpty, size, slice } from 'lodash';
import { hot } from 'react-hot-loader/root';
import request from 'superagent';
import ReactPaginate from 'react-paginate';

import { muiTheme } from './utils/tools';
import EventsCard from './components/EventsCard';
import Loader from './utils/Loader';
import config from './config';
import Selector from './utils/Selector';
import {
  filterEventList,
  GenerateDateFilterListDataSource,
} from './utils/dates';

const NUMBER_OF_ITEMS_PER_PAGE =
  parseInt(window.itemPerPage, 10) > 0 ? parseInt(window.itemPerPage, 10) : 10;

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN } = this.props;
    if (cityId || aggloId) {
      this.fetchEvents(cityId, aggloId);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchEventsByCode(cityINSEE, aggloSIREN);
    }
  }

  fetchEvents(cityId, aggloId) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .then(res => res.body)
      .then(events => {
        this.setState({
          events: filterEventList(events),
          fetched: true,
          dateOptions: GenerateDateFilterListDataSource(events),
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchEventsByCode(cityINSEE, aggloSIREN) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-insee': cityINSEE })
      .query({ 'agglo-siren': aggloSIREN })
      .then(res => res.body)
      .then(events => {
        this.setState({
          events: filterEventList(events),
          fetched: true,
          dateOptions: GenerateDateFilterListDataSource(events),
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  selectFilter(option) {
    console.group('Select filter option');
    console.log(option);
    console.groupEnd();
    this.setState({ filterSelected: option, currentPage: 0 });
  }

  handlePageClick = data => {
    console.log(data);
    this.setState({ currentPage: data.selected });
    document.activeElement.blur();

    if (window.scrollToTop) {
      const elmnt = document.getElementById('intramuros_events');
      if (elmnt) {
        elmnt.scrollIntoView(true);
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  render() {
    const {
      events,
      error,
      fetched,
      filterSelected,
      dateOptions,
      currentPage,
    } = this.state;
    const filter = window.dateFilter;
    if (!events) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    let divStyle = { overflowY: 'auto', overflowX: 'hidden' };
    if (window.maxHeight > 0) {
      divStyle = {
        maxHeight: parseInt(window.maxHeight, 10),
        overflowY: 'auto',
        overflowX: 'hidden',
      };
    }

    let myEvents = events;
    if (filter && filterSelected) {
      myEvents = filterEventList(
        myEvents,
        filterSelected.month,
        filterSelected.year,
      );
    }

    const numberOfEvents = size(myEvents);
    const numberOfPages = Math.ceil(numberOfEvents / NUMBER_OF_ITEMS_PER_PAGE);
    console.log('number of pages = ' + numberOfPages);

    //  Calculate list of current page
    if (numberOfPages > 1) {
      myEvents = slice(
        myEvents,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE + NUMBER_OF_ITEMS_PER_PAGE,
      );
    }

    return (
      <div style={divStyle}>
        <MuiThemeProvider theme={muiTheme}>
          {filter && !isEmpty(myEvents) ? (
            <Selector
              options={dateOptions}
              selectOption={option => this.selectFilter(option)}
            />
          ) : null}
          {myEvents.map(theEvent => (
            <EventsCard event={theEvent} key={theEvent.id} />
          ))}
          {numberOfPages > 1 ? (
            <Grid container justify="center" style={{ marginTop: 30 }}>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={numberOfPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1} // saut de page: quand on clique sur les ...
                onPageChange={this.handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={currentPage}
              />
            </Grid>
          ) : null}
        </MuiThemeProvider>
      </div>
    );
  }
}

Events.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default hot(Events);
