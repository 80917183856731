import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MuiThemeProvider } from '@material-ui/core';
import { orderBy } from 'lodash';
import { hot } from 'react-hot-loader/root';
import request from 'superagent';
import Slider from 'react-slick';
import { muiTheme } from './utils/tools';
import EventsCard from './components/EventsCard';
import Loader from './utils/Loader';
import config from './config';

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: window.speed ? window.speed * 1000 : 5000,
  pauseOnHover: false,
  // fade: true,
};

class Diaporama extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN } = this.props;
    if (cityId || aggloId) {
      this.fetchEvents(cityId, aggloId);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchEventsByCode(cityINSEE, aggloSIREN);
    }
  }

  fetchEvents(cityId, aggloId) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .then(res => res.body)
      .then(items => {
        this.setState({
          items: orderBy(items, ['start_date'], ['asc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchEventsByCode(cityINSEE, aggloSIREN) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-insee': cityINSEE })
      .query({ 'agglo-siren': aggloSIREN })
      .then(res => res.body)
      .then(items => {
        this.setState({
          items: orderBy(items, ['start_date'], ['asc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  render() {
    const { items, error, fetched } = this.state;
    if (!items) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    return (
      <div
        style={{
          marginRight: '3em',
          marginLeft: '3em',
          marginTop: '3em',
        }}
      >
        <MuiThemeProvider theme={muiTheme}>
          <Slider {...settings}>
            {items.map(theEvent => (
              <EventsCard event={theEvent} key={theEvent.id} slickCarousel />
            ))}
          </Slider>
        </MuiThemeProvider>
      </div>
    );
  }
}

Diaporama.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default hot(Diaporama);
