import { createMuiTheme } from '@material-ui/core/styles';

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#2869E5',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      paper: '#EBF1F4',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      root: {
        // Some CSS
        height: 24,
        width: 24,
      },
    },
    MuiInputBase: {
      input: {
        height: 19,
      },
    },
  },
});
