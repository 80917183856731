const dev = {
  apiRoot: 'https://staging.api.appli-intramuros.com',
  apiEvents: '/events/',
  apiCities: '/locations/cities/',
  apiCategories: '/categories/',
  apiDirectories: '/services/directories/',
  apiNews: '/news/',
  apiAnecdote: '/anecdotes/',
  apiPoi: '/poi/',
  apiSurveys: '/services/survey/',
  apiSurveyResults: '/services/surveyresults/',
  apiSurveyResultsAggregated: '/services/surveyresults_aggregated/',
};

const prod = {
  apiRoot: 'https://api.appli-intramuros.com',
  apiEvents: '/events/',
  apiCities: '/locations/cities/',
  apiCategories: '/categories/',
  apiDirectories: '/services/directories/',
  apiNews: '/news/',
  apiAnecdote: '/anecdotes/',
  apiPoi: '/poi/',
  apiSurveys: '/services/survey/',
  apiSurveyResults: '/services/surveyresults/',
  apiSurveyResultsAggregated: '/services/surveyresults_aggregated/',
};

let config = dev;
if (process.env.NODE_ENV === 'production') {
  config = prod;
}

export default {
  ...config,
};
