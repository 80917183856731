import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, CardMedia, Divider, Hidden } from '@material-ui/core';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import LazyLoad from 'react-lazy-load';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdAttachFile } from 'react-icons/md';
import {
  formatDateLong,
  formatDateCalendar,
  numberOfDays,
} from '../utils/dates';
import { formattingTextToHTML } from '../utils/rendering';
import DialogBox from './DialogBox';
import logoPrice from '../assets/price-green.png';
import logoSchedule from '../assets/schedule-green.png';
import Button from '../utils/Button';
import CalendarModal from '../utils/CalendarModal';

const calculateSpacing = () => {
  const factor = window.zoom ? window.zoom : 1;
  if (window.containerSize === 'small') return 4 * factor;
  if (window.containerSize === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 8 * factor;
};

const calculateMarginBottom = () => {
  const factor = window.zoom ? window.zoom : 1;
  if (window.containerSize === 'small') return 8 * factor;
  if (window.containerSize === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 10 * factor;
};

const spacing = calculateSpacing();
const marginBottom = calculateMarginBottom();
const zoom = window.zoom ? window.zoom : 1;
const imageMaxHeight = parseInt(window.imageMaxHeight, 10)
  ? parseInt(window.imageMaxHeight, 10)
  : null;

class EventsCard extends PureComponent {
  state = {
    open: false,
  };

  displayDate = (startDate, endDate) => {
    if (startDate === endDate) {
      return formatDateLong(startDate);
    }
    return `${formatDateLong(startDate)} - ${formatDateLong(endDate)}`;
  };

  displayMapLink = (label, latitude, longitude) => {
    const link =
      'https://www.google.com/maps/search/?api=1&query=' +
      latitude +
      ',' +
      longitude;
    return (
      <a target="_blank" href={link} rel="noopener noreferrer">
        {label}
      </a>
    );
  };

  generateCardMedia = event => {
    return (
      <CardMedia
        component="img"
        alt={event.title}
        image={event.image}
        title={event.title}
      />
    );
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  onHandleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, event, slickCarousel } = this.props;
    if (!event) return null;

    const { open } = this.state;

    // Si fullPage ou diapo on affiche sur trois colonnes pour les grands écrans
    let smallContainer = false;
    let mediumContainer = true;
    let largeContainer = false;

    if (window.isDiapo === 'true') {
      smallContainer = false;
      mediumContainer = false;
      largeContainer = true;
    } else {
      if (window.containerSize === 'small') {
        smallContainer = true;
        mediumContainer = false;
        largeContainer = false;
        console.log('containerSize is ' + window.containerSize);
      }
      if (window.containerSize === 'medium') {
        smallContainer = false;
        mediumContainer = true;
        largeContainer = false;
        console.log('containerSize is ' + window.containerSize);
      }
      if (window.containerSize === 'large') {
        smallContainer = false;
        mediumContainer = false;
        largeContainer = true;
        console.log('containerSize is ' + window.containerSize);
      }
    }

    const eventCalendar = {
      description: event.description,
      duration: 24 + 24 * numberOfDays(event.end_date, event.start_date),
      endDatetime: formatDateCalendar(event.end_date) + 'T200000',
      location: event.address_label,
      startDatetime: formatDateCalendar(event.start_date) + 'T080000',
      title: event.title,
      timezone: 'Europe/Paris',
    };

    const AddToCalendarModal = AddToCalendarHOC(Button, CalendarModal);

    return (
      <div>
        <Grid
          container
          spacing={0}
          style={{ paddingRight: spacing, paddingLeft: spacing }}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={10} sm={11} style={{}}>
                <Typography
                  align="left"
                  className={classes.date}
                  variant="body1"
                >
                  {this.displayDate(event.start_date, event.end_date)}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={1} style={{}}>
                <AddToCalendarModal
                  className={classes.addToCalendarComponent}
                  linkProps={{
                    className: classes.addToCalendarLink,
                  }}
                  buttonText="Ajouter au calendrier"
                  event={eventCalendar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} style={{}}>
              <Typography
                align="left"
                className={classes.title}
                variant="body1"
              >
                {event.title}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            lg={(() => {
              if (largeContainer) return 3;
              if (mediumContainer) return 3;
              if (smallContainer) return 6;
              return 3;
            })()}
            style={{
              paddingRight: spacing,
              paddingLeft: spacing,
            }}
          >
            {slickCarousel ? (
              <CardMedia
                component="img"
                alt={event.title}
                className={classes.image}
                image={event.image}
                title={event.title}
                onClick={this.handleClickOpen}
              />
            ) : (
              <LazyLoad
                offsetHorizontal={50}
                debounce={false}
                offsetVertical={500}
              >
                <CardMedia
                  component="img"
                  alt={event.title}
                  className={classes.image}
                  style={imageMaxHeight ? { maxHeight: imageMaxHeight } : {}}
                  image={event.image}
                  title={event.title}
                  onClick={this.handleClickOpen}
                />
              </LazyLoad>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            lg={(() => {
              if (largeContainer) return 6;
              if (mediumContainer) return 9;
              if (smallContainer) return 12;
              return 9;
            })()}
            style={{
              paddingRight: spacing,
              paddingLeft: spacing,
            }}
          >
            <Hidden xsDown>
              <Grid item xs={12} style={{}}>
                <Typography
                  align="left"
                  className={classes.title}
                  variant="body1"
                >
                  {event.title}
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12} style={{}}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-start"
                style={{ marginBottom }}
              >
                <FaMapMarkerAlt
                  style={{
                    alignItems: 'center',
                    color: '#3a4da7',
                  }}
                  size={25 * zoom}
                />
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontWeight: 'bold',
                    fontSize: 14 * zoom,
                    marginLeft: 10,
                    lineHeight: 'normal',
                  }}
                  variant="subtitle1"
                >
                  {this.displayMapLink(
                    event.address_label,
                    event.latitude,
                    event.longitude,
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{}}>
              <Typography
                align="left"
                className={classes.description}
                variant="body1"
                component="div" // IMPORTANT pour ne pas se retrouver avec des blaises interdites dans le tag <p>
                dangerouslySetInnerHTML={{
                  __html: formattingTextToHTML(event.description),
                }}
              />

              {event.author ? (
                <Typography
                  align="left"
                  className={classes.description}
                  variant="body1"
                  style={{
                    fontStyle: 'italic',
                    lineHeight: 'normal',
                    fontSize: 14 * zoom,
                  }}
                >
                  {'Publié par ' + event.author}
                </Typography>
              ) : null}

              {event.attachment ? (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                  style={{ marginBottom }}
                >
                  <MdAttachFile
                    style={{
                      alignItems: 'center',
                      color: '#000000',
                    }}
                    size={20 * zoom}
                  />
                  <Grid item xs={10}>
                    <Typography
                      align="left"
                      style={{
                        color: '#666',
                        marginLeft: 10,
                        fontSize: 14 * zoom,
                        lineHeight: 'normal',
                      }}
                      variant="body2"
                    >
                      <a
                        href={event.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit' }}
                      >
                        {'Télécharger la pièce jointe'}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={largeContainer ? 3 : 12}
            style={{
              paddingRight: spacing,
              paddingLeft: spacing,
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justify="center"
            >
              {event.schedule ? (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                  style={{
                    marginBottom,
                    flexWrap: 'nowrap',
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Horaires"
                    image={logoSchedule}
                    title="Horaires"
                    style={{
                      maxWidth: 35 * zoom,
                    }}
                  />

                  <Typography
                    align="left"
                    style={{
                      color: '#666',
                      marginLeft: 10 * zoom,
                      fontSize: 14 * zoom,
                      lineHeight: 'normal',
                      wordBreak: 'break-word',
                    }}
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: formattingTextToHTML(event.schedule, true),
                    }}
                  />
                </Grid>
              ) : null}

              {event.price ? (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                  style={{
                    marginBottom,
                    flexWrap: 'nowrap',
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Tarifs"
                    image={logoPrice}
                    title="Tarifs"
                    style={{
                      maxWidth: 35 * zoom,
                    }}
                  />
                  <Typography
                    align="left"
                    style={{
                      color: '#666',
                      marginLeft: 10 * zoom,
                      fontSize: 14 * zoom,
                      lineHeight: 'normal',
                      wordBreak: 'break-word',
                    }}
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: formattingTextToHTML(event.price, true),
                    }}
                  />
                </Grid>
              ) : null}

              {!slickCarousel && (event.url1 || event.url2 || event.url3) ? (
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justify="center"
                  style={{ marginBottom }}
                >
                  <Grid item xs={12}>
                    <Typography
                      align="left"
                      style={{
                        color: '#2DA192',
                        fontSize: 15 * zoom,
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                      variant="body1"
                    >
                      {"PLUS D'INFORMATIONS"}
                    </Typography>
                  </Grid>
                  {event.url1 ? (
                    <Grid item xs={12}>
                      <Typography
                        align="left"
                        style={{
                          color: '#666',
                          fontSize: 14 * zoom,
                          lineHeight: 'normal',
                        }}
                        noWrap
                        variant="body2"
                      >
                        <a
                          href={event.url1}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Voir le lien'}
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}

                  {event.url2 ? (
                    <Grid item xs={12}>
                      <Typography
                        align="left"
                        style={{
                          color: '#666',
                          fontSize: 14 * zoom,
                          lineHeight: 'normal',
                        }}
                        noWrap
                        variant="body2"
                      >
                        <a
                          href={event.url2}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Voir le lien 2'}
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}
                  {event.url3 ? (
                    <Grid item xs={12}>
                      <Typography
                        align="left"
                        style={{
                          color: '#666',
                          fontSize: 14 * zoom,
                          lineHeight: 'normal',
                        }}
                        noWrap
                        variant="body2"
                      >
                        <a
                          href={event.url3}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Voir le lien 3'}
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          open={open}
          content={this.generateCardMedia(event)}
          title={event.title}
          handleClose={this.onHandleClose}
        />
        {!slickCarousel ? (
          <Divider
            variant="middle"
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        ) : null}
      </div>
    );
  }
}

EventsCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  event: PropTypes.objectOf(PropTypes.object()).isRequired,
  slickCarousel: PropTypes.bool,
};

const styles = () => ({
  date: {
    color: '#2DA192',
    lineHeight: 'normal',
    fontSize: 18 * zoom,
    fontWeight: 'bold',
    marginBottom,
    marginTop: 15,
  },
  title: {
    color: '#333',
    lineHeight: 'normal',
    fontSize: 18 * zoom,
    fontWeight: 'bold',
    marginBottom,
  },
  description: {
    fontSize: 16 * zoom,
    color: '#333',
    marginBottom,
    wordBreak: 'break-word',
  },
  image: {
    cursor: 'pointer',
    marginBottom,
  },
  addToCalendarComponent: {
    marginLeft: 10,
  },
  addToCalendarLink: {
    marginLeft: 10,
    marginRight: 10,
    color: '#2869E5',
    textDecoration: 'none',
    fontSize: 16,
  },
});

export default withStyles(styles)(EventsCard);
