import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { split, toNumber } from 'lodash';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
}));

export default function NativeSelects({ options, selectOption }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    date: '',
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    });
    selectOption({
      year: toNumber(split(event.target.value, '-')[0]),
      month: toNumber(split(event.target.value, '-')[1]),
    });
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          ref={inputLabel}
          key={inputLabel}
          htmlFor="outlined-date-native-simple"
          style={{
            fontSize: 16,
          }}
        >
          Mois
        </InputLabel>
        <Select
          native
          value={state.date}
          onChange={handleChange('date')}
          labelWidth={labelWidth}
          inputProps={{
            name: 'date',
            id: 'outlined-date-native-simple',
          }}
          style={{
            fontSize: 16,
          }}
        >
          <option value="" />
          <option value="0-0">Tous</option>
          {options.map(option => (
            <option value={option.key} key={option.key}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

NativeSelects.propTypes = {
  options: PropTypes.objectOf(PropTypes.object()).isRequired,
  selectOption: PropTypes.objectOf(PropTypes.object()).isRequired,
};
