import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, CardMedia, Divider } from '@material-ui/core';
import LazyLoad from 'react-lazy-load';
import { toUpper } from 'lodash';
import { MdAttachFile } from 'react-icons/md';
import { formattingTextToHTML } from '../utils/rendering';
import { formatDateLongFirstLetterUpper } from '../utils/dates';
import DialogBox from './DialogBox';
import logoPrice from '../assets/price-yellow.png';
import logoSchedule from '../assets/schedule-yellow.png';

const calculateSpacing = () => {
  const factor = window.zoom ? window.zoom : 1;
  if (window.containerSize === 'small') return 4 * factor;
  if (window.containerSize === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 8 * factor;
};

const calculateMarginBottom = () => {
  const factor = window.zoom ? window.zoom : 1;
  if (window.containerSize === 'small') return 8 * factor;
  if (window.containerSize === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 10 * factor;
};

const spacing = calculateSpacing();
const marginBottom = calculateMarginBottom();
const zoom = window.zoom ? window.zoom : 1;
const imageMaxHeight = parseInt(window.imageMaxHeight, 10)
  ? parseInt(window.imageMaxHeight, 10)
  : null;

class NewsCard extends PureComponent {
  state = {
    open: false,
  };

  generateCardMedia = news => {
    return (
      <CardMedia
        component="img"
        alt={news.title}
        image={news.image}
        title={news.title}
      />
    );
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  onHandleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, news } = this.props;
    if (!news) return null;

    const { open } = this.state;

    // Si fullPage ou diapo on affiche sur trois colonnes pour les grands écrans
    let smallContainer = false;
    let mediumContainer = true;
    let largeContainer = false;

    if (window.isDiapo === 'true') {
      smallContainer = false;
      mediumContainer = false;
      largeContainer = true;
    } else {
      if (window.containerSize === 'small') {
        smallContainer = true;
        mediumContainer = false;
        largeContainer = false;
        console.log('containerSize is ' + window.containerSize);
      }
      if (window.containerSize === 'medium') {
        smallContainer = false;
        mediumContainer = true;
        largeContainer = false;
        console.log('containerSize is ' + window.containerSize);
      }
      if (window.containerSize === 'large') {
        smallContainer = false;
        mediumContainer = false;
        largeContainer = true;
        console.log('containerSize is ' + window.containerSize);
      }
    }

    return (
      <div>
        <Grid
          container
          spacing={0}
          style={{
            paddingRight: spacing,
            paddingLeft: spacing,
          }}
        >
          <Typography align="left" className={classes.title} variant="body1">
            {toUpper(news.title)}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            lg={(() => {
              if (largeContainer) return 3;
              if (mediumContainer) return 3;
              if (smallContainer) return 6;
              return 3;
            })()}
            style={{ paddingRight: spacing, paddingLeft: spacing }}
          >
            <LazyLoad
              offsetHorizontal={50}
              debounce={false}
              offsetVertical={500}
            >
              <CardMedia
                component="img"
                alt={news.title}
                className={classes.image}
                style={imageMaxHeight ? { maxHeight: imageMaxHeight } : {}}
                image={news.image}
                title={news.title}
                onClick={this.handleClickOpen}
              />
            </LazyLoad>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            lg={(() => {
              if (largeContainer) return 6;
              if (mediumContainer) return 9;
              if (smallContainer) return 12;
              return 9;
            })()}
            style={{ paddingRight: spacing, paddingLeft: spacing }}
          >
            <Grid item xs={12} style={{}}>
              <Typography
                align="left"
                style={{
                  color: '#666',
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  fontSize: 14 * zoom,
                  marginBottom,
                }}
                variant="subtitle1"
              >
                {'Publié le ' + formatDateLongFirstLetterUpper(news.updated_at)}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{}}>
              <Typography
                align="left"
                className={classes.description}
                variant="body1"
                component="div" // IMPORTANT pour ne pas se retrouver avec des blaises interdites dans le tag <p>
                dangerouslySetInnerHTML={{
                  __html: formattingTextToHTML(news.description, news.id < 0),
                }}
              />

              {news.author ? (
                <Typography
                  align="left"
                  className={classes.description}
                  variant="body1"
                  style={{
                    fontStyle: 'italic',
                    lineHeight: 'normal',
                    fontSize: 14 * zoom,
                  }}
                >
                  {'Publié par ' + news.author}
                </Typography>
              ) : null}

              {news.attachment ? (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                  style={{ marginBottom }}
                >
                  <MdAttachFile
                    style={{
                      alignItems: 'center',
                      color: '#000000',
                    }}
                    size={20 * zoom}
                  />
                  <Grid item xs={10}>
                    <Typography
                      align="left"
                      style={{
                        color: '#666',
                        marginLeft: 10,
                        fontSize: 14 * zoom,
                        lineHeight: 'normal',
                      }}
                      variant="body2"
                    >
                      <a
                        href={news.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit' }}
                      >
                        {'Télécharger la pièce jointe'}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={largeContainer ? 3 : 12}
            style={{ paddingRight: spacing, paddingLeft: spacing }}
          >
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justify="center"
            >
              {news.schedule ? (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                  style={{
                    flexWrap: 'nowrap',
                    marginBottom,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Horaires"
                    image={logoSchedule}
                    title="Horaires"
                    style={{
                      maxWidth: 35 * zoom,
                    }}
                  />

                  <Typography
                    align="left"
                    style={{
                      color: '#666',
                      marginLeft: 10 * zoom,
                      fontSize: 14 * zoom,
                      lineHeight: 'normal',
                      wordBreak: 'break-word',
                    }}
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: formattingTextToHTML(news.schedule, true),
                    }}
                  />
                </Grid>
              ) : null}

              {news.price ? (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                  style={{
                    flexWrap: 'nowrap',
                    marginBottom,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Tarifs"
                    image={logoPrice}
                    title="Tarifs"
                    style={{
                      maxWidth: 35 * zoom,
                    }}
                  />

                  <Typography
                    align="left"
                    style={{
                      color: '#666',
                      marginLeft: 10 * zoom,
                      fontSize: 14 * zoom,
                      lineHeight: 'normal',
                      wordBreak: 'break-word',
                    }}
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: formattingTextToHTML(news.price, true),
                    }}
                  />
                </Grid>
              ) : null}

              {news.url1 || news.url2 || news.url3 ? (
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justify="center"
                  style={{ marginBottom }}
                >
                  <Grid item xs={12}>
                    <Typography
                      align="left"
                      style={{
                        color: '#003057',
                        fontSize: 15 * zoom,
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                      }}
                      variant="body1"
                    >
                      {"PLUS D'INFORMATIONS"}
                    </Typography>
                  </Grid>
                  {news.url1 ? (
                    <Grid item xs={12}>
                      <Typography
                        align="left"
                        style={{
                          color: '#666',
                          fontSize: 14 * zoom,
                          lineHeight: 'normal',
                        }}
                        noWrap
                        variant="body2"
                      >
                        <a
                          href={news.url1}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Voir le lien'}
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}

                  {news.url2 ? (
                    <Grid item xs={12}>
                      <Typography
                        align="left"
                        style={{
                          color: '#666',
                          fontSize: 14 * zoom,
                          lineHeight: 'normal',
                        }}
                        noWrap
                        variant="body2"
                      >
                        <a
                          href={news.url2}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Voir le lien 2'}
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}
                  {news.url3 ? (
                    <Grid item xs={12}>
                      <Typography
                        align="left"
                        style={{
                          color: '#666',
                          fontSize: 14 * zoom,
                          lineHeight: 'normal',
                        }}
                        noWrap
                        variant="body2"
                      >
                        <a
                          href={news.url3}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Voir le lien 3'}
                        </a>
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          open={open}
          content={this.generateCardMedia(news)}
          title={news.title}
          handleClose={this.onHandleClose}
        />
        <Divider
          variant="middle"
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        />
      </div>
    );
  }
}

NewsCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  news: PropTypes.objectOf(PropTypes.object()).isRequired,
};

const styles = () => ({
  title: {
    color: '#003057',
    lineHeight: 'normal',
    fontSize: 16 * zoom,
    fontWeight: 'bold',
    marginBottom,
    marginTop: 15,
  },
  description: {
    color: '#333',
    fontSize: 16 * zoom,
    marginBottom,
    wordBreak: 'break-word',
  },
  image: {
    cursor: 'pointer',
    marginBottom,
  },
});

export default withStyles(styles)(NewsCard);
